import './App.css';
import Header from './components/header'
import Footer from './components/footer'
import LandingPage from './pages/landingPage';
import FormPage from './pages/formPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <Router>
      <Header>
      </Header>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/form" element={<FormPage />} />
      </Routes>
      <Footer>
      </Footer>
      </Router>
    </div>
  );
}

export default App;
