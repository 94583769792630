import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="w-full bg-blue-600 py-4 mt-8">
      <p className="text-center text-white">&copy; 2024 Insurance Codex. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
