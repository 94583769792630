import emailjs from 'emailjs-com';
import React, { useState } from 'react';

const FormPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    insurance_types: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (data) => {
    const serviceID = 'service_f33vu9c';
    const templateID = 'template_h12x6zn';
    const userID = 'maVvIeALJ9D2zGjt4';

    if (formData.name.trim() === '' && 
      formData.email.trim() === '' && 
      formData.phone.trim() === '' && 
      formData.insurance_types.trim() === '') {
        return;
    }

    emailjs.send(serviceID, templateID, data, userID)
        .then(response => {
            console.log('Email sent successfully:', response.status, response.text);
        })
        .catch(error => {
            console.error('Error sending email:', error);
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    sendEmail(formData);
    setFormData({
      name: '',
      email: '',
      phone: '',
      inurance_types: ''
    });
  };

  return (
    <div className="p-8">
      <main className="min-h-screen flex-grow flex flex-col items-center justify-left">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
          <h2 className="text-2xl font-bold mb-4 text-center">Get Your Free Insurance Quote</h2>
          <p className="mb-8 text-center">Fill out the form below and you will be contacted by an experienced and licensed insurance agent in your state.</p>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700 justify-left" style={{textAlign: 'left'}}>Name</label>
              <input
                type="text"
                name="name"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" style={{textAlign: 'left'}}>Email</label>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" style={{textAlign: 'left'}}>Phone Number</label>
              <input
                type="tel"
                name="phone"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" style={{textAlign: 'left'}}>What types of insurance are you interested in?</label>
              <input
                type="text"
                name="inurance_types"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Home owner's insurance, life insurance, etc..."
                value={formData.insurance_types}
                onChange={handleChange}
              />
            </div>

            <div class="g-recaptcha" data-sitekey="6LcuxQ0qAAAAACumUo6bvF-9vTjeqTwzJjf7O1tI"></div>

            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Get Quote
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default FormPage;