import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return ( 
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">

      <main className="flex-grow flex flex-col items-center justify-center">
        <section className="bg-white shadow-md rounded-lg p-8 max-w-4xl w-full text-center my-8">
          <h2 className="text-4xl font-bold mb-4">Insurance Codex</h2>
          <p className="mb-8 text-lg">
            Don't try to navigate the insurance field on your own! Our Licensed Agents have the tools, 
            knowledge, experience, and insurance carrier network to enable them to find you the best 
            coverage at the best price.  
          </p>
          <img
            src="https://st3.depositphotos.com/3116407/32198/i/450/depositphotos_321989138-stock-photo-concept-of-life-home-and.jpg"
            alt="Insurance"
            className="mx-auto mb-8"
          />
        </section>

        <section className="bg-white shadow-md rounded-lg p-8 max-w-4xl w-full text-center my-8">
          <h3 className="text-3xl font-bold mb-4">Why Choose Us?</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-2xl font-bold mb-2">Expert Advice</h4>
              <p>Our team of experts is here to provide you with personalized advice to find the best insurance plan for you.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-2xl font-bold mb-2">Competitive Rates</h4>
              <p>We compare rates from multiple providers to ensure you get the most competitive prices on the market.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-2xl font-bold mb-2">Trusted Providers</h4>
              <p>We work only with trusted and reliable insurance providers to give you peace of mind and security.</p>
            </div>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-8 max-w-4xl w-full text-center my-8">
          <h3 className="text-3xl font-bold mb-4">Get Started Today</h3>
          <p className="mb-8 text-lg">
            Join thousands of satisfied customers who have found the perfect insurance plans with our help.
          </p>
          <Link to="/form">
          <button
            className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Learn More
          </button>
          </Link>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;