import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-gray-800 p-4 text-white align-top flex flex-column">
      <nav className="w-full">
        <ul className="flex space-x-4 items-center w-full">
          <li><Link to="/"><img src="img/logo.png" alt="logo" className='w-16 h-16'></img></Link></li>
          <li><Link to="/form" className="text-lg hover:underline">Book Appt</Link></li>
        </ul>
      </nav>
      <div className='items-center'>
        <h1 className="text-3xl text-white w-full text-end">Insurance Codex</h1>
      </div>
    </header>
  );
};

export default Header;
